<template>
    <div class="welcome_box" v-if="type === 'welcome' && clientPortal.welcome_settings">
        <h3>{{ clientPortal.welcome_settings.title }}</h3>
        <div class="assigned_coach">
            <div class="mr-auto">
                <h4>{{ clientPortal.welcome_settings.coach_text }}</h4>
                <h5>{{ clientPortal.welcome_settings.coach_sub_text }}</h5>
            </div>
            <ul class="coach_list" v-if="clientPortal.welcome_settings.has_coaches && coaches.length">
                <template v-for="(coach, c) of coaches" :key="c">
                    <li class="coach_box" v-if="coach.enabled">
                        <img v-if="coach.profile_pic" :src="coach.profile_pic" alt="">
                        <div v-else class="coach_name">
                            <template v-if="coach.fname">{{ coach.fname[0] }}</template>
                            <template v-if="coach.lname">{{ coach.lname[0] }}</template>
                        </div>
                    </li>
                </template>
            </ul>
        </div>
        <div class="video_wpr" v-if="(clientPortal.welcome_settings.has_welcome_video && clientPortal.welcome_settings.welcome_video.type == 1 && clientPortal.welcome_settings.welcome_video.link) || (clientPortal.welcome_settings.welcome_video.type == 2 && clientPortal.welcome_settings.welcome_video.embed)">
            <div v-if="clientPortal.welcome_settings.welcome_video.type == 1" v-html="parseEmbedCode(clientPortal.welcome_settings.welcome_video.link)"></div>
            <div v-else-if="clientPortal.welcome_settings.welcome_video.type == 2" v-html="clientPortal.welcome_settings.welcome_video.embed"></div>
        </div>
        <img src="@/assets/images/thumb/video-placeholder.svg" alt=""  v-if="(clientPortal.welcome_settings.welcome_video.type == 1 && !clientPortal.welcome_settings.welcome_video.link) || (clientPortal.welcome_settings.welcome_video.type == 2 && !clientPortal.welcome_settings.welcome_video.embed)">
        <img :src="`${ clientPortal.welcome_settings.welcome_video.image ? clientPortal.welcome_settings.welcome_video.image : '@/assets/images/thumb/video-placeholder.svg' }`" alt=""  v-if="clientPortal.welcome_settings.welcome_video.type == 3">
        <div class="welcome_info" v-html="clientPortal.welcome_settings.welcome_text"></div>
        <section class="box_footer">
            <button type="button" class="cta_more" v-if="clientPortal.welcome_settings.has_onboarding && clientPortal.welcome_settings.onboarding_cta_text">{{ clientPortal.welcome_settings.onboarding_cta_text }}<i class="fas fa-chevron-right"></i></button>
        </section>
    </div>
    <div class="announcement_box" v-if="type === 'announcement'">
        <div class="box_header">
            <h3 class="mb-2">{{ announcements.length ? announcements[0].title : 'What\'s New!' }}</h3>
            <div class="box_icon">
                <img src="@/assets/images/announcement.svg" alt="Announcement">
            </div>
        </div>
        <div class="box_content" v-if="announcements.length">
            <div class="announcement_item" v-for="(announcement, a) of announcements" :key="a">
                <div class="date_time">
                    <div class="mr-auto">{{moment.utc(announcement.created_at).tz(user && user.timezone ? user.timezone : 'UTC').format('ll')}}</div>
                    <div><span class="tag">{{moment.utc(announcement.created_at).tz(user && user.timezone ? user.timezone : 'UTC').format('hh:mm A')}}</span>{{ user.timezone ? user.timezone : 'UTC' }}</div>
                    <div class="options" @click.stop="toggleOption($event)" v-click-outside="closeAllToggleOption">
                        <i class="fas fa-ellipsis-v"></i>
                        <div class="dropdown_wpr">
                            <ul>
                                <li @click="handlePin(announcement.is_pinned, announcement.id)">
                                    {{ announcement && announcement.is_pinned ? 'Unpin' : 'Pin' }}
                                </li>
                                <li v-if="announcements.length > 1" @click="archiveGuide(announcement.is_archived, announcement.id)">
                                    Archive
                                </li>
                                <li class="danger" v-if="announcements.length > 1" @click="deletePrompt(announcement.id)">Delete</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="announcement_text" :style="`background: ${announcement.bg_color};`">
                    <div class="saved_wpr">
                        <span class="pin" v-if="announcement.is_pinned"><i class="fas fa-thumbtack"></i></span>
                        <div class="action_list">
                            <span class="btn edit" @click="handleEditAnnouncement($event)"><i class="fas fa-pen"></i></span>
                        </div>
                        <span class="tag live" v-if="announcement.is_published">Live</span>
                        <p :style="`color: ${clientPortal.announcement_text_color ? announcement.text_color : '#000'};`" v-html="announcement.message"></p>
                        <div class="cta_btn" v-if="announcement.has_include_link">
                            <a :href="announcement.link_address" target="_blank" :style="`color: ${announcement.link_color}`">{{ announcement.link_text }}</a>
                        </div>
                    </div>
                    <div class="rename_fld">
                        <redactor v-model="announcement.message" name="announcement_message" ref="step-editor" :reset-to-defaut-watcher="announcementMessageDefaultContentWatcher" :email-editor="false"/>
                        <div class="capsule_elm mt-3">
                            <h5 class="large">Include Link</h5>
                            <label :for="`announcement_link_${announcement.id}`" class="switch_option capsule_btn">
                                <input type="checkbox" :id="`announcement_link_${announcement.id}`" :true-value="1" :false-value="0" v-model="announcement.has_include_link" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <template v-if="announcement.has_include_link">
                            <div class="field_wpr">
                                <input type="text" placeholder="Learn More" v-model="announcement.link_text">
                            </div>
                            <div class="field_wpr has_prefix">
                                <input type="text" placeholder="Learn More" v-model="announcement.link_address">
                                <span class="prefix">URL</span>
                            </div>
                            <div class="mt-3">
                                <color-picker v-model="announcement.link_color"/>
                            </div>
                            <div class="capsule_elm mt-3">
                                <h5 class="large">Notify Clients</h5>
                                <label :for="`has_notification_${announcement.id}`" class="switch_option capsule_btn">
                                    <input type="checkbox" :id="`has_notification_${announcement.id}`" v-model="announcement.has_notification" :true-value="1" :false-value="0" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                        </template>
                        <div class="action_wpr">
                            <div class="capsule_elm mr-auto">
                                <label :for="`is_published_${announcement.id}`" class="switch_option capsule_btn py-1">
                                    <input type="checkbox" :id="`is_published_${announcement.id}`" v-model="announcement.is_published" :true-value="1" :false-value="0" hidden>
                                    <div><span></span></div>
                                </label>
                                <span class="stat live">Live</span>
                            </div>
                            <button type="button" :disabled="announcementSettingUpdateLoader" class="btn cancel_btn bg-light" @click="handleEditAnnouncement($event)">Cancel</button>
                            <button type="button" :disabled="announcementSettingUpdateLoader" class="btn save_btn" @click="handleEditAnnouncement($event, announcement)">
                                <i class="fa fa-spin fa-spinner" v-if="announcementSettingUpdateLoader"></i>
                                {{ announcementSettingUpdateLoader ? 'Saving' : 'Save' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <section class="box_footer">
                <button type="button" :disabled="announcementSettingAddLoader" class="add_btn" @click="addAnnouncement(clientPortal.id)">
                    <i class="fa fa-spin fa-spinner" v-if="announcementSettingAddLoader"></i>
                    {{ announcementSettingUpdateLoader ? 'Adding' : '+ Add' }}
                </button>
                <button type="button" class="cta_more" @click="showAnnouncementList = true;">Manage All<i class="fas fa-chevron-right"></i></button>
            </section>
        </div>
    </div>
    <div class="task_box" v-if="type === 'tasks'">
        <div class="box_header">
            <h3 class="mb-2">{{ clientPortal && clientPortal.calendar_settings && clientPortal.calendar_settings.title ? clientPortal.calendar_settings.title : '' }}</h3>
            <div class="box_icon">
                <img src="@/assets/images/tasks-alt.svg" alt="Launch">
            </div>
        </div>
        <div class="box_content">
            <div class="session_type">
                <div class="session" v-if="clientPortal && clientPortal.calendar_settings && clientPortal.calendar_settings.has_morning_label">
                    <span style="background-color: var(--var-calendar-morning-icon-color-light); color: var(--var-calendar-morning-text-color);">Morning</span>
                </div>
                <div class="task_item">
                    <div class="icon">
                        <svg width="43" height="50" viewBox="0 0 43 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.26182 0.643096C2.93026 2.37333 0 6.15527 0 11.3061C0 14.5201 1.00626 16.8265 3.35546 18.9959C4.88518 20.4085 4.97496 20.6037 4.40915 21.2842C2.97388 23.0109 0.919439 27.6959 0.492541 30.2154C-0.162204 34.0834 0.499741 37.6451 2.61517 41.6355C4.58915 45.3588 8.17796 48.75 10.9579 49.5189C13.2736 50.16 29.2103 50.1604 31.5769 49.5201C32.7529 49.2014 34.1823 48.258 35.9712 46.6189C40.7141 42.2734 42.998 36.0663 42.0075 30.2154C41.5806 27.6959 39.5261 23.0109 38.0908 21.2842C37.525 20.6037 37.6148 20.4085 39.1445 18.9959C41.5026 16.8182 42.5 14.5225 42.5 11.2728C42.5 6.71572 39.8768 2.86377 35.54 1.05336C33.403 0.16126 32.8923 0.122932 21.8853 0.0298499C11.7511 -0.0561922 10.2015 0.0138151 8.26182 0.643096ZM32.2413 5.26591C35.4765 5.86312 37.9557 9.79837 36.9842 12.7934C36.4874 14.3238 34.2251 16.7482 33.2929 16.7482C33.0172 16.7482 31.9563 16.3172 30.9357 15.7904C25.4211 12.944 17.0789 12.944 11.5643 15.7904C9.19989 17.0107 8.98136 16.9974 7.3572 15.5311C5.52002 13.8725 4.93049 11.9365 5.58185 9.70255C6.13368 7.80923 8.33254 5.5737 9.91986 5.29133C11.4733 5.01521 30.765 4.99292 32.2413 5.26591ZM20.403 17.1088C21.3279 17.356 21.2174 17.4268 19.5272 17.6697C13.0369 18.6036 7.37372 23.5898 5.47216 30.0457L4.7234 32.5878L4.86273 29.0679C5.01393 25.2492 5.50901 23.9781 7.91369 21.2345C10.6682 18.0921 16.6655 16.1111 20.403 17.1088Z" fill="#929292"/>
                        </svg>
                    </div>
                    <div class="task_details">
                        <h4>Workouts - Ab Accelerator</h4>
                        <button type="button" class="go_btn">Go</button>
                    </div>
                </div>
            </div>
            <div class="session_type">
                <div class="session" v-if="clientPortal && clientPortal.calendar_settings && clientPortal.calendar_settings.has_afternoon_label">
                    <span style="background-color: var(--var-calendar-afternoon-icon-color-light); color: var(--var-calendar-afternoon-text-color);">Afternoon</span>
                </div>
                <div class="task_item">
                    <div class="icon">
                        <svg width="72" height="95" viewBox="0 0 72 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M55.0604 30.6056L37.7012 3.49162C37.5005 3.17808 37.2241 2.92007 36.8975 2.74137C36.5709 2.56266 36.2046 2.46899 35.8323 2.46899C35.46 2.46899 35.0937 2.56266 34.7671 2.74137C34.4404 2.92007 34.1641 3.17808 33.9633 3.49162L16.6037 30.6058C13.5942 34.6885 11.9767 39.6304 11.9901 44.7024C11.9901 57.8489 22.6855 68.5446 35.8323 68.5446C48.979 68.5446 59.6745 57.8492 59.6745 44.7024C59.6879 39.6303 58.0703 34.6882 55.0604 30.6056Z" fill="#ffffff"/>
                            <path d="M68.5477 58.5047C67.8027 51.7107 65.4322 45.5971 63.4514 41.0283C58.1224 28.7466 50.8646 17.8536 45.1368 9.73545C43.9457 8.04723 42.7456 6.43963 41.4756 4.73711C40.903 3.96907 40.3227 3.19193 39.7362 2.39745C38.9591 1.34378 37.7477 0.6984 36.4404 0.64032C36.3763 0.637719 36.3126 0.635986 36.2484 0.635986C35.0097 0.635986 33.826 1.16694 33.0016 2.09925C32.9023 2.21194 32.8217 2.29993 32.7554 2.37318C32.5855 2.56042 32.4095 2.75373 32.2166 3.00816C26.5331 10.5018 21.865 17.3131 17.9455 23.8324C11.8393 33.9907 8.00342 42.1908 5.50945 50.4217C2.83865 59.2338 2.94311 67.0338 5.82759 74.2695C10.848 86.862 22.7778 94.9984 36.2207 95.0001C36.2224 95.0001 36.2228 95.0001 36.2246 95.0001C45.2504 95.0001 53.6646 91.3541 59.9159 84.7334C66.7572 77.4882 69.6617 68.6626 68.5477 58.5047Z" fill="#929292"/>
                            <path d="M35.5571 73.9586C34.5545 75.5172 32.663 76.2537 30.6493 75.8462C28.2234 75.3556 26.3055 74.0796 24.9839 71.9722C21.4818 66.3857 20.5283 60.4178 22.0886 53.9796C23.4336 48.4325 25.7615 43.2972 28.2615 38.2174C28.3205 38.0978 28.4254 38.0003 28.3569 38.0891C28.063 41.5059 27.5338 45.096 27.4931 48.6922C27.422 54.9843 29.4799 60.6982 32.9231 65.9289C33.6383 67.0146 34.4024 68.0683 35.1288 69.1467C36.1539 70.668 36.6541 72.2544 35.5571 73.9586Z" fill="#ffffff"/>
                        </svg>
                    </div>
                    <div class="task_details">
                        <h4>Habits - Ab Accelerator</h4>
                        <button type="button" class="go_btn">Go</button>
                    </div>
                </div>
            </div>
            <div class="session_type">
                <div class="session" v-if="clientPortal && clientPortal.calendar_settings && clientPortal.calendar_settings.has_evening_label">
                    <span style="background-color: var(--var-calendar-evening-icon-color-light); color: var(--var-calendar-evening-text-color);">Evening</span>
                </div>
                <div class="task_item">
                    <div class="icon">
                        <svg width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.45 27H14.85C21.6 27 24.3 24.3 24.3 17.55V9.45C24.3 2.7 21.6 0 14.85 0H9.45C2.7 0 0 2.7 0 9.45V17.55C0 24.3 2.7 27 9.45 27Z" fill="#929292"/>
                            <path d="M19.4045 7.26124C15.368 3.67024 9.26599 3.67024 5.22949 7.26124L8.17249 11.9862C10.535 9.88019 14.099 9.88019 16.4615 11.9862L19.4045 7.26124Z" stroke="#C2DCFF" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11.9377 6.65669C11.9458 6.54553 12.0383 6.45947 12.1498 6.45947C12.2613 6.45947 12.3538 6.54553 12.3619 6.65669L13.4335 21.3815C13.4878 22.1271 12.8974 22.762 12.1498 22.762C11.4022 22.762 10.8118 22.1272 10.8661 21.3815L11.9377 6.65669Z" fill="#ffffff"/>
                        </svg>
                    </div>
                    <div class="task_details">
                        <h4>Check-ins - Ab Accelerator</h4>
                        <button type="button" class="go_btn">Go</button>
                    </div>
                </div>
            </div>
            <div class="session_type">
                <div class="session" v-if="clientPortal && clientPortal.calendar_settings && clientPortal.calendar_settings.has_other_label">
                    <span>Other</span>
                </div>
                <div class="task_item">
                    <div class="icon">
                        <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.7 14.5H10.7V13.5H3.7V14.5ZM3.7 10.5H10.7V9.5H3.7V10.5ZM1.81537 18C1.35512 18 0.97083 17.8458 0.662497 17.5375C0.354164 17.2292 0.199997 16.8449 0.199997 16.3846V1.61537C0.199997 1.15512 0.354164 0.770833 0.662497 0.4625C0.97083 0.154167 1.35512 0 1.81537 0H9.7L14.2 4.5V16.3846C14.2 16.8449 14.0458 17.2292 13.7375 17.5375C13.4292 17.8458 13.0449 18 12.5846 18H1.81537ZM9.2 5H13.2L9.2 1V5Z" fill="#929292"/>
                        </svg>
                    </div>
                    <div class="task_details">
                        <h4>Forms - Ab Accelerator Feedback</h4>
                        <button type="button" class="go_btn">Go</button>
                    </div>
                </div>
            </div>
            <section class="box_footer">
                <button type="button" class="cta_more" @click="this.$emit('update:tab', 'calendar');">Open Calendar<i class="fas fa-chevron-right"></i></button>
            </section>
        </div>
    </div>
    <announcement-list v-model:show="showAnnouncementList" v-model:archive-guide="disableArchiveGuide" v-model:notification-guide="disableNotificationGuide"/>
    <div class="modal secondary prompt" v-if="confirmation_prompt">
        <div class="modal_container">
            <img src="@/assets/images/alert.svg" alt="" class="logo_img">
            <h5>This action is pernmanent. Would you rather archive this message?</h5>
            <div class="action_wpr mt-3">
                <button type="button" class="btn cancel_btn" @click="confirmation_prompt = false;">Yes</button>
                <button type="button" class="btn save_btn" @click="deleteAnnouncement()">No, Delete it</button>
            </div>
        </div>
    </div>
    <div class="modal secondary prompt" v-if="archivePrompt">
        <div class="modal_container">
            <img src="@/assets/images/alert.svg" alt="" class="logo_img">
            <h5>This message has been archived. To see archived messages click "Manage All" below.</h5>
            <label for="disable_archive_guide" class="checkbox">
                <input type="checkbox" id="disable_archive_guide" v-model="disableArchiveGuide" :true-value="1" :false-value="0" hidden>
                <span><i class="fas fa-check"></i></span> Don't show it again.
            </label>
            <div class="action_wpr mt-3">
                <button type="button" class="btn save_btn" @click="archivePrompt = false;">Okay</button>
            </div>
        </div>
    </div>
    <div class="modal secondary prompt" v-if="notificationPrompt">
        <div class="modal_container">
            <img src="@/assets/images/alert.svg" alt="" class="logo_img">
            <h5>Once your announcement is published, we'll notify your clients one-time via the portal chat widget. It will remain visible in your announcement settings until you delete, unpublish, or archive it.</h5>
            <label for="disable_notification_guide" class="checkbox">
                <input type="checkbox" id="disable_notification_guide" v-model="disableNotificationGuide" :true-value="1" :false-value="0" hidden>
                <span><i class="fas fa-check"></i></span> Don't show it again.
            </label>
            <div class="action_wpr mt-3">
                <button type="button" class="btn save_btn" @click="notificationPrompt = false;">Okay</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    import moment from 'moment-timezone'
    import VideoParser from '@/utils/VideoParser'

    const AnnouncementList = defineAsyncComponent(() => import('@/pages/client-portal/components/AnnouncementList'))

    export default {
        data(){
            return{
                showAnnouncementList: false,
                moment,
                confirmation_prompt: false,
                promptId: 0,
                archivePrompt: false,
                disableArchiveGuide: 0,
                notificationPrompt: false,
                disableNotificationGuide: 0,
                coaches: [],
                announcements: [],
                announcementMessageDefaultContentWatcher: 0,
            }
        },
        components: {
            AnnouncementList,
        },

        props: {
            type: String,
            tab: String,
        },

        emits: ['update:tab'],

        watch:{
            showAnnouncementList (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            confirmation_prompt (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            clientPortal: {
                handler (clientPortal) {
                    const vm = this;

                    vm.coaches = vm.clientPortal.welcome_settings ? JSON.parse(JSON.stringify(vm.clientPortal.welcome_settings.active_coaches)) : [];

                    if (vm.coaches.length) {
                        vm.coaches.reverse();
                    }

                    vm.announcements = clientPortal.announcement_settings ? JSON.parse(JSON.stringify(clientPortal.announcement_settings)) : [];

                    setTimeout(() => {
                        vm.announcementMessageDefaultContentWatcher++;
                    }, 1000);
                },
                deep: true,
            }
        },

        computed: mapState({
            user: state => state.authModule.user,
            clientPortal: state => state.clientPortalModule.clientPortal,
            announcementSettingAddLoader: state => state.clientPortalModule.announcementSettingAddLoader,
            announcementSettingUpdateLoader: state => state.clientPortalModule.announcementSettingUpdateLoader,
        }),

        mounted () {
            const vm = this;

            vm.coaches = vm.clientPortal.welcome_settings ? JSON.parse(JSON.stringify(vm.clientPortal.welcome_settings.active_coaches)) : [];

            if (vm.coaches.length) {
                vm.coaches.reverse();
            }

            vm.announcements = vm.clientPortal.announcement_settings ? JSON.parse(JSON.stringify(vm.clientPortal.announcement_settings)) : [];

            setTimeout(() => {
                vm.announcementMessageDefaultContentWatcher++;
            }, 1000);
        },

        methods:{
            ...mapActions({
                addAnnouncement: 'clientPortalModule/addAnnouncement',
                updateAnnouncementSetting: 'clientPortalModule/updateAnnouncementSetting',
                deleteAnnouncementSetting: 'clientPortalModule/deleteAnnouncementSetting',
            }),

            deletePrompt(id){
                const vm = this;
                vm.confirmation_prompt = true;
                vm.promptId = id;
            },

            deleteAnnouncement(){
                const vm = this;

                vm.form = {
                    id: vm.promptId,
                };
                vm.confirmation_prompt = false;
                const params = JSON.parse(JSON.stringify(vm.form));

                vm.deleteAnnouncementSetting(params);
            },

            archiveGuide(value, id){
                const vm = this;

                if(!vm.disableArchiveGuide){
                    vm.archivePrompt = true;
                }

                vm.form = {
                    id: id,
                    is_archived: !value,
                };
                const params = JSON.parse(JSON.stringify(vm.form));

                vm.updateAnnouncementSetting(params);
            },

            notificationGuide(){
                const vm = this;

                if(!vm.disableNotificationGuide){
                    vm.notificationPrompt = true;
                }
            },

            handleEditAnnouncement (ev, params = null) {
                const vm = this;
                const elm = ev.currentTarget.closest('.announcement_text');

                if (params) {
                    vm.updateAnnouncementSetting(params).then((result) => {
                        if (result) {
                            if (elm.classList.contains('editable')) {
                                elm.classList.remove('editable');
                            } else{
                                elm.classList.add('editable');
                            }
                        }
                    })
                } else {
                    if (elm.classList.contains('editable')) {
                        elm.classList.remove('editable');
                    } else{
                        elm.classList.add('editable');
                    }
                }
            },

            toggleOption(ev){
                const vm = this;
                let elm = ev.currentTarget;
                let targetElm = elm.closest('.options').querySelector('.dropdown_wpr');
                
                if(targetElm.classList.contains('active')){
                    targetElm.classList.remove('active');
                } else{
                    vm.closeAllToggleOption();
                    targetElm.classList.add('active');
                }
            },

            closeAllToggleOption(){
                let allTargetElm = document.querySelectorAll('.announcement_item .options .dropdown_wpr');
                allTargetElm.forEach(function(item){
                    if(item.classList.contains('active')){
                        item.classList.remove('active');
                    }
                });
            },

            parseEmbedCode (url) {
                const vm = this;

                return VideoParser.embedCodeFromUrl(url);
            },

            handlePin (value, id) {
                const vm = this;
                vm.form = {
                    id: id,
                    is_pinned: !value,
                };
                const params = JSON.parse(JSON.stringify(vm.form));

                vm.updateAnnouncementSetting(params);
            },
        }
    }
</script>

<style scoped>
    .box_footer{
        position: sticky;
        margin-top: auto;
        bottom: -1px;
        padding: 25px;
        background: #fff;
        border-radius: 0 0 10px 10px;
        margin: auto -25px 0 -25px;
        display: flex;
        justify-content: flex-end;
    }
    .box_footer .cta_more{
        font-size: 15px;
        line-height: 18px;
        font-weight: 400;
        color: #121525;
        cursor: pointer;
        text-decoration: none;
    }
    .box_footer .cta_more i{
        font-size: 12px;
        padding-left: 10px;
        color: #999;
    }
    .welcome_box{
        display: flex;
        flex-direction: column;
        background: #fff;
        border-radius: 10px;
        padding: 30px 25px 0 25px;
        max-height: 670px;
        overflow-y: auto;
        height: 100%;
    }
    .welcome_box h3{
        font-size: 22px;
        line-height: 30px;
        font-weight: 400;
        color: #121525;
        margin: 0;
    }
    .assigned_coach{
        display: flex;
        align-items: center;
        padding: 20px 0 30px 0;
    }
    .assigned_coach .coach_list{
        display: flex;
        flex-direction: row-reverse;
        margin-left: 12px;
        padding: 0;
    }

    .assigned_coach .coach_list li:not(:first-child){
        margin-right: -15px;
    }
    .assigned_coach .coach_box {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #fff;
        padding: 2px;
        flex-shrink: 0;
        position: relative;
        z-index: 1;
    }
    .assigned_coach .coach_box:before{
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #fff;
        /* background: var(--var-portal-tab-bg-color20); */
        z-index: 0;
    }
    .assigned_coach .coach_box .coach_name{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        background: var(--var-portal-tab-background-color);
        color: #fff;
        position: relative;
        z-index: 1;
    }
    .assigned_coach .coach_box img{
        width: 36px;
        height: 36px;
        border-radius: 50%;
    }
    .assigned_coach h4 {
        font-size: 17px;
        line-height: 23px;
        font-weight: 400;
        color: #121525;
        padding-bottom: 6px;
    }
    .assigned_coach h5 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #8b8b8b;
    }
    .welcome_box .welcome_info{
        font-size: 15px;
        line-height: 27px;
        font-weight: 400;
        color: #5a5a5a;
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .announcement_box,
    .task_box{
        background: #fff;
        border-radius: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .announcement_box .box_header,
    .task_box .box_header{
        padding: 30px 20px 20px 20px;
        display: flex;
        align-items: center;
        /* border-bottom: 1px solid #e9e9e9; */
    }
    .announcement_box .box_header h3,
    .task_box .box_header h3{
        font-size: 22px;
        line-height: 30px;
        font-weight: 400;
        color: #121525;
        margin: 0;
    }
    .announcement_box .box_header label,
    .task_box .box_header label{
        font-size: 25px;
        line-height: 30px;
        font-weight: 300;
        color: #121525;
    }
    .announcement_box .box_header .box_icon,
    .task_box .box_header .box_icon{
        height: 50px;
        width: 50px;
        padding: 10px;
        border-radius: 50%;
        background: var(--var-portal-tab-bg-color12);
        margin-left: auto;
        position: relative;
    }
    .announcement_box .box_header .box_icon img{
        position: absolute;
        left: 10px;
        bottom: 10px;
        height: 39px;
        max-height: none;
        max-width: none;
    }
    .announcement_box .box_content,
    .task_box .box_content{
        flex-grow: 1;
        padding: 15px 25px 0 25px;
        display: flex;
        flex-direction: column;
        max-height: 570px;
        overflow-y: auto;
    }
    .welcome_box::-webkit-scrollbar,
    .announcement_box .box_content::-webkit-scrollbar,
    .task_box .box_content::-webkit-scrollbar{
        width: 4px;
    }
    .welcome_box::-webkit-scrollbar-thumb,
    .announcement_box .box_content::-webkit-scrollbar-thumb,
    .task_box .box_content::-webkit-scrollbar-thumb{
        background: #dbdbdb;
        border-radius: 2px;
    }
    .box_content .add_btn{
        margin: 0 auto 0 0;
        font-weight: 400;
    }
    .announcement_box .announcement_item:not(:first-of-type){
        margin-top: 40px;
    }
    .announcement_box .date_time{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #5a5a5a;
        padding-bottom: 14px;
        display: flex;
        align-items: center;
    }
    .announcement_box .date_time .options{
        position: relative;
        margin-left: 15px;
        color: #121525;
        font-weight: 400;
        cursor: pointer;
    }
    .announcement_box .date_time .options .dropdown_wpr{
        left: auto;
        right: 0;
    }
    .announcement_box .date_time .options .dropdown_wpr:before{
        display: none;
    }
    .announcement_box .tag{
        background: #e9e9e9;
        color: #5a5a5a;
        padding: 3px 8px;
        margin-right: 6px;
        border-radius: 10px;
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        text-transform: uppercase;
    }
    .announcement_box .announcement_text{
        width: 100%;
        padding: 40px 20px 20px 20px;
        border-radius: 8px;
        position: relative;
    }
    .announcement_box .announcement_text p{
        font-size: 15px;
        line-height: 27px;
        font-weight: 400;
        color: #5a5a5a;
        min-height: 27px;
    }
    .announcement_box .announcement_text p :deep(p:empty){
        min-height: 27px;
    }
    .announcement_box .announcement_text .cta_btn{
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid var(--var-announcement-text-color20);
    }
    .announcement_box .announcement_text .cta_btn a{
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        text-decoration: none;
        cursor: pointer;
    }
    .announcement_box .announcement_text .cta_btn a:not(:last-child){
        border-right: 1px solid #cfcfcf;
    }
    .announcement_box .announcement_text .archive_btn{
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #8a8a8a;
    }
    .announcement_box .announcement_text .action_list{
        position: absolute;
        right: -5px;
        top: -5px;
        display: flex;
        flex-direction: column;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        z-index: 2;
    }
    .announcement_box .announcement_text .action_list .btn{
        width: 22px;
        height: 22px;
        flex-shrink: 0;
        font-size: 10px;
        line-height: 20px;
        display: block;
        text-align: center;
        border: 1px solid #d9d9d9;
        background: #fff;
        color: #5a5a5a;
        cursor: pointer;
        border-radius: 50%;
        margin: 0 0 7px 0;
    }
    .announcement_box .announcement_text .pin{
        width: 22px;
        height: 22px;
        flex-shrink: 0;
        position: absolute;
        left: 50%;
        top: -8px;
        transform: translateX(-50%);
        font-size: 10px;
        line-height: 20px;
        display: block;
        text-align: center;
        border: 1px solid #d9d9d9;
        background: #fff;
        color: #121525;
        cursor: pointer;
        border-radius: 50%;
        transform: rotate(30deg);
    }
    .announcement_box .announcement_text .tag{
        position: absolute;
        left: 20px;
        top: 15px;
        background: #fff;
        font-size: 11px;
        color: #2f7eed;
        text-transform: capitalize;
    }
    .announcement_box .announcement_text .tag.live{
        color: #14af03;
    }
    .announcement_box .announcement_text:hover .action_list{
        opacity: 1;
    }
    .announcement_box .announcement_text .rename_fld .field_wpr{
        margin-top: 15px;
    }
    .announcement_box .announcement_text .rename_fld :deep(.field_wpr input){
        height: 40px;
    }
    .announcement_box .announcement_text .rename_fld :deep(.field_wpr.has_prefix){
        padding-left: 50px;
    }
    .announcement_box .announcement_text .rename_fld :deep(.field_wpr.has_prefix .prefix){
        width: 50px;
        height: 40px;
    }
    .announcement_box .announcement_text .rename_fld :deep(.pickr .pcr-button){
        width: 24px;
        height: 24px;
    }
    .announcement_box .announcement_text .rename_fld :deep(.color-picker .swatches){
        background: #fff;
        border-radius: 5px;
        padding: 5px;
        margin: 0;
    }
    .announcement_box .announcement_text .rename_fld :deep(.color-picker .swatches li){
        padding: 5px;
    }
    .announcement_box .announcement_text .rename_fld :deep(.color-picker .swatches li span){
        width: 20px;
        height: 20px;
    }
    .announcement_box .announcement_text .rename_fld .action_wpr{
        margin: 15px 0 5px 0;
    }
    .announcement_box .announcement_text .rename_fld,
    .announcement_box .announcement_text.editable .saved_wpr{
        display: none;
    }
    .announcement_box .announcement_text.editable .rename_fld,
    .announcement_box .announcement_text .saved_wpr{
        display: block;
    }
    .announcement_box .announcement_text .rename_fld :deep(.redactor-toolbar-wrapper){
        top: -15px !important;
    }
    .announcement_box .announcement_text .rename_fld :deep(.redactor-box.redactor-styles-on .redactor-toolbar) {
        padding: 10px !important;
    }
    .announcement_box .announcement_text .rename_fld :deep(.redactor-toolbar a.re-button-icon, .redactor-air a.re-button-icon) {
        font-size: 12px;
        padding: 5px !important;
        margin-right: 4px !important;
    }
    .announcement_box .announcement_text .rename_fld :deep(.redactor-box .redactor-styles){
        resize: vertical;
    }
    .announcement_box .announcement_text .action_wpr .btn{
        padding: 0 10px;
        font-size: 11px;
        min-width: 70px;
        height: 30px;
    }
    .announcement_box .announcement_text .action_wpr .stat{
        background: #fff;
        border-radius: 10px;
        padding: 2px 6px;
        margin-left: 7px;
        font-size: 11px;
        line-height: 14px;
        color: #999;
        font-weight: 500;
    }
    .announcement_box .announcement_text .action_wpr .stat.live{
        color: #14af03;
    }
    .announcement_box .announcement_text .action_wpr .btn:first-of-type{
        margin-left: 0;
    }

    .session_type{
        padding-bottom: 18px;
    }
    .session_type:not(:last-of-type){
        margin-bottom: 20px;
        border-bottom: 1px solid #e9e9e9;
    }
    .session_type .session{
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-bottom: 3px;
    }
    .session_type .session > span{
        background: rgba(44, 62, 80, 0.1);
        padding: 5px 15px;
        border-radius: 8px;
        position: relative;
        z-index: 0;
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        font-weight: 400;
    }
    .task_item{
        display: flex;
        align-items: center;
        padding: 12px 0;
    }
    .task_item:not(:last-of-type){
        border-bottom: 1px solid #e9e9e9;
    }
    .task_item .icon{
        flex-shrink: 0;
    }
    .task_item .icon svg{
        width: 15px;
        height: 17px;
    }
    .task_item .task_details{
        flex: 1 1 auto;
        padding-left: 12px;
        display: flex;
        align-items: center;
    }
    .task_item .task_details h4{
        font-size: 15px;
        line-height: 22px;
        font-weight: 400;
        color: #121525;
        margin-right: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }
    .task_item .task_details .go_btn{
        background: var(--var-portal-tab-bg-color12);
        border-radius: 5px;
        padding: 3px 10px;
        margin-left: auto;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: var(--var-portal-tab-background-color);
        cursor: pointer;
    }
    .modal.prompt h5{
        max-width: 425px;
    }
    .modal.prompt .close_btn {
        position: absolute;
        right: -7px;
        left: auto;
        top: -7px;
        border: 1px solid #c7c7c7;
        background: rgba(50, 55, 59, 0.7);
        color: #fff;
        border-radius: 50%;
        font-size: 11px;
        width: 22px;
        height: 22px;
        cursor: pointer;
        z-index: 1;
    }
    .modal.prompt .logo_img{
        max-height: 90px;
        width: auto;
        margin-bottom: 30px;
    }
    .modal.prompt label.checkbox{
        justify-content: flex-start;
        align-items: center;
        font-size: 15px;
        line-height: 18px;
        font-weight: 400;
        color: #121525;
        margin: 10px 0;
    }
    .modal.prompt label.checkbox span{
        margin-right: 10px;
    }
    @media(max-width: 1199px){
        .announcement_box .announcement_text .action_wpr .btn{
            min-width: 70px;
            margin-left: 7px;
        }
    }
    @media(max-width: 768px){
        .welcome_box,
        .announcement_box .box_content,
        .task_box .box_content{
            max-height: none;
        }
        .announcement_box .announcement_text p,
        .announcement_box .announcement_text p :deep(p:empty){
            min-height: 20px;
        }
    }
</style>